import React from "react";

const Profile = () => {
  return (
    <>
      <h1 className="text-9xl">Profile </h1>
    </>
  );
};

export default Profile;
